<template>
  <tbody>
  <tr class="text-center">
    <td>{{ $t('labels.timekeeping') }}</td>
    <td>
      <SelectMultiplePos :label="$t('labels.pos')" :placeholder="$t('labels.pos')" name="id_poss" :default-value="['all']" @onFilter="onFilterChange"/>
    </td>
    <td>
      <SelectMultipleEmployeeByPos :label="$t('labels.employee')" :placeholder="$t('labels.employee')" name="id_employees" :default-value="['all']" :id-poss="filters.id_poss" @onFilter="onFilterChange" />
    </td>
    <td>
      <DateRangeFilter :disabled="isDisabledDateRange" :label="$t('labels.from_to')" :placeholder="$t('labels.from_to')"
                       :min-date="minDate" name="dates" :default-value="filters.dates" @onFilter="onFilterChange" />
    </td>
    <td>
      <v-btn color="success" :disabled="isDisabledAction" @click="exportExcel"><v-icon>mdi-download</v-icon></v-btn>
    </td>
    <td>
      <v-btn color="info" @click="showListDialog"><v-icon>mdi-information-outline</v-icon></v-btn>
      <v-dialog v-model="listDialog" persistent max-width="100vw">
        <TimekeepingList v-if="listDialog" :p-filters="filters" @cancel="hideListDialog"/>
      </v-dialog>
    </td>
  </tr>
  </tbody>
</template>

<script>

import moment from "moment/moment";

export default {
  name: "Timekeeping",
  components: {
    SelectMultiplePos: () => import('@/components/administration/SelectMultiplePos'),
    SelectMultipleEmployeeByPos: () => import('@/components/administration/SelectMultipleEmployeeByPos'),
    TimekeepingList: () => import('@/components/administration/TimekeepingList'),
    DateRangeFilter: () => import('@/components/table/DateRangeFilter'),
  },
  data: () => ({
    filters: {
      id_poss: [],
      id_employees: [],
      dates: [],
    },
    minDate: null,
    listDialog: false,
    isLoading: false
  }),
  computed: {
    isDisabledDateRange() {
      return !this.filters.id_employees || this.filters.id_employees.length === 0
    },
    isDisabledAction() {
      return this.isDisabledDateRange || !this.filters.dates || this.filters.dates.length === 0
    }
  },
  created() {
    let startOfSubMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
    let endOfSubMonth = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    const {startDate, endDate, minDate} = this.defaultDataDate({
      startDate: startOfSubMonth,
      endDate: endOfSubMonth,
      poss: window.me && window.me.poss || []
    })
    this.minDate = minDate
    this.filters = {...this.filters, dates: [startDate, endDate]}
  },
  methods: {
    onFilterChange(filter) {
      this.filters = {...this.filters, [filter.name]: filter.value}
    },
    showListDialog() {
      this.listDialog = true
    },
    hideListDialog() {
      this.listDialog = false
    },
    async exportExcel() {
      this.$emit('exportExcel', {params: this.filters, type: 'pos_timekeeping'})
    },
  }
}
</script>

<style scoped>

</style>
